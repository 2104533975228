<template>
  <el-container class="main-container">
    <el-aside width="200px" v-if="loginState">
      <leftnav></leftnav>
    </el-aside>
    <el-container>
      <el-header>
        <navcon></navcon>
      </el-header>
      <el-main>
        <!--路由占位符-->
        <router-view></router-view>
        <div class="footer">
            Powered By <a class="link" href="https://github.com/ArvinJr/rina-songlist-vue" target="_blank">rina-songlist-vue</a>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import leftnav from '@/PC/components/leftnav'
import navcon from '@/PC/components/navcon'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'PC',
  components: {
    leftnav,
    navcon
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(['loginState'])
  },
  created() {
    const state = window.sessionStorage.getItem('state')
    if (!this.loginState && state !== 'null') {
      if (state === 'false') {
        this.setLoginState(false)
      }
      if (state === 'true') {
        this.setLoginState(true)
      }
    }
  },
  methods: {
    ...mapMutations(['setLoginState'])
  },
  beforeMount() {
    // 在进入普通用户界面前将登录状态改为未登录
    if (this.$route.path === '/pc/public') {
      this.setLoginState(false)
    }
  }
}
</script>

<style lang="less" scoped>
.main-container {
  height: 100%;
}

.el-header {
  padding: 0;
}
.footer{
  color: #666;
  height: 250px;
  text-align: center;
  font-size: 16px;
  padding-top: 20px;
  position: relative;
}
.link {
  color: #409eff;
  cursor: pointer;
}
.link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>
